import React from 'react';
import { Typography, Row, Col, Layout } from 'antd';
import { SITE_NAME } from 'settings';

const TermOfUse = () => {
  const { Paragraph, Title } = Typography;
  const { Content } = Layout;
  document.title = `${SITE_NAME} - Termo de Uso`;

  return (
    <Layout className="tos-page internal-page">
      <Row gutter={50}>
        <Col xs={24} sm={10} className="col-image-margin"></Col>

        <Col xs={24} sm={14} className="col-text" style={{marginTop: 10, marginBottom: 10}}>
          <div className="wrapper">
            <Title level={1}>Termo de Uso</Title>

            <Content>
              <Paragraph>
                São condições de acesso que devem ser aplicadas ao uso dos dados e
                produtos disponíveis no site do BNDMET.
              </Paragraph>

              <ol>
                <li>
                  Os direitos autorais do conteúdo gráfico, textos, imagens e multimídias
                  são protegidos por legislação nacional e, em nível internacional, pelo que
                  prevê a Convenção de Berna.
                </li>

                <li>
                  Não estão autorizados links cujo resultado seja a exibição de uma
                  página ou imagem do BNDMET emoldurada em outros sites que não tenham
                  sido previamente autorizados nos termos em vigor nessa página.
                </li>

                <li>
                  Os links estão autorizados e devem estar apontados, inequivocamente,
                  como links de acesso ao BNDMET, exclusivamente à página principal.
                </li>

                <li>
                  Todos os acessos a este Servidor são registrados e, em caso de
                  invasão danosa a este site ou a qualquer parte do seu conteúdo, essas
                  informações serão utilizadas para identificar o autor.
                </li>

                <li>
                  O BNDMET não se responsabiliza por eventuais danos que o conteúdo
                  hospedado possa causar, sejam eles morais ou materiais.
                </li>
                <li>
                  As imagens bem como todos os dados contidos neste servidor
                  pertencem à administração do site e do seu comitê gestor.
                </li>
                <li>
                  O BNDMET não se responsabiliza por interrupção e falta de acesso aos
                  serviços do site durante seu uso.
                </li>
              </ol>
            </Content>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default TermOfUse;
