import React from 'react';
import { Button } from 'antd';

interface IProps {
  text: string;
  type: string | string[];
  value: string | string[];
  activeStationsLength?: number;
  disabled?: boolean;
  callback: Function;
}

const ParamsButton = (props: IProps) => {
  const { text, type, value, disabled, activeStationsLength = 0, callback } = props;

  const handleClick = () => {
    if (type instanceof Array) {
      callback((prev: string[]) => {
        if (prev.includes(text)) {
          const index = prev.indexOf(text);
          delete prev[index];
          return [...prev];
        }

        return [ ...prev, text];
      });
      return;
    }

    callback(text);
  }

  const isButtonSelected = type instanceof Array
    ? type.includes(text)
    : type === text;

  const fakeButtonActiveClass = activeStationsLength > 0 && !value
    ? ' fake-active'
    : '';

  return (
    <Button
      shape="round"
      size="small"
      className={isButtonSelected && disabled
        ? 'param-button disabled'
        : `param-button${fakeButtonActiveClass}`
      }
      disabled={disabled}
      type={isButtonSelected ? 'primary' : 'default'}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
}

export default ParamsButton;