import { enBaseColors } from 'interfaces/enums';
import dateFnsFormat from 'date-fns/format';
import locale from 'date-fns/locale/pt-BR';

export const stationColor = (index: number) => {
  const colors = [
    enBaseColors.PRIMARY,
    enBaseColors.SECONDARY,
    enBaseColors.THIRD
  ];

  if (index >= 0 && index <= 2) return colors[index];
  return enBaseColors.STATION_OUTHER;
}

export const formatDate = (date: Date, format: string = 'dd/MM/yyyy') => {
  return dateFnsFormat(date, format, { locale });
}