import React from 'react'
import { Row, Col, Typography, Layout } from 'antd'
import { SITE_NAME } from 'settings'

const Contact = () => {
  document.title = `${SITE_NAME} - Contato. Estamos aqui para lhe ajudar!`;

  const { Paragraph, Title } = Typography

  return (
    <Layout className="contact-page internal-page">
      <Row gutter={50}>
        <Col xs={24} sm={10} className="col-image-margin" />

        <Col xs={24} sm={14} className="col-content">
          <div className="wrapper">
            <div className="col-text">
              <Title level={1}>Contato</Title>
              <Title level={4}>Estamos aqui para ajudar!</Title>
              <Paragraph>
                Visitou o site e não encontrou os dados que você buscava? Ficou em dúvida
                sobre algo específico? Não se preocupe. Estamos aqui para ajudá-lo com
                toda a segurança e a rapidez que você necessita..
              </Paragraph>

              <Paragraph>
                Entre em contato conosco. É muito fácil. Basta preencher o formulário ao
                lado. Teremos a grata satisfação de ajudar você a navegar com muito mais
                otimização por aqui.
              </Paragraph>

              <Paragraph>
                Preencha o formulário com os dados solicitados:
              </Paragraph>
            </div>

            <iframe
              frameBorder="0"
              src="https://servicos.decea.gov.br/sac/?a=atd&amp;=c=342&amp;source=bndmet"
              height="750px"
              width="100%"
              title="form"
            />
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default Contact
