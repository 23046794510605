import { createActions, createReducer } from 'reduxsauce';
import { IFilter, IFilterParams, IFilterSetClassesAction, IFilterSetAttributesAction  } from 'interfaces/filter';
import { Dispatch } from 'redux';
import { normalizeStationClasses, normalizeStationAttributes } from 'helpers/stationsNormalize';
import { fetchApi } from 'helpers/api';
import { handleError } from 'helpers/error';
import { IStationStartLoadingAction, IStationErrorAction } from 'interfaces/station';

export const { Types, Creators } = createActions({
  setData: ['payload'],
  setDataClasses: ['payload'],
  setDataAttributes: ['payload'],
  clientError: ['payload'],
  startLoadingClasses: [],
  startLoadingAttributes: [],
  resetAttributes: []
});

const INITIAL_STATE: IFilter = {
  activeItems: [],
  classes: {
    Nuvem: { Intervalo: []},
    /* eslint-disable */
    'Precipitação': { Intervalo: []},
    'Pressão atmosférica': { Intervalo: []},
    'Radiação Solar': { Intervalo: []},
    Temperatura: { Intervalo: []},
    'Tensão': { Intervalo: []},
    /* eslint-enable */
    Umidade: { Intervalo: []},
    Vento: { Intervalo: []}
  },
  intervals: [
    { id: 0, text: 'Horário' },
    { id: 1, text: 'Diário' },
    { id: 2, text: 'Mensal' }
  ],
  phenomenons: [
    { id: 0, text: 'PRESSAO ATMOSFERICA MEDIA DIARIA (AUT)' },
    { id: 1, text: 'CH (NUVENS ALTAS)' },
    { id: 2, text: 'CL (NUVENS BAIXAS)' },
    { id: 3, text: 'CM (NUVENS MEDIAS)' },
    { id: 4, text: 'PRECIPITAÇÃO TOTAL' },
    { id: 5, text: 'TEMPERATURA MÁXIMA' },
    { id: 5, text: 'UMIDADE RELATIVA DO AR' },
    { id: 5, text: 'VENTO' }
  ],
  attributes: [],
  loadingClasses: false,
  loadingAttributes: false
};

const startLoadingClasses = (state = INITIAL_STATE, action?: IStationStartLoadingAction) => ({
  ...state, loadingClasses: action?.payload || true
});

const resetAttributes = (state = INITIAL_STATE, action?: IStationStartLoadingAction) => ({
  ...state, attributes: []
});

const startLoadingAttributes = (state = INITIAL_STATE, action?: IStationStartLoadingAction) => ({
  ...state, loadingAttributes: action?.payload || true
});

const setDataClasses = (state = INITIAL_STATE, action: IFilterSetClassesAction) => {
  const { payload } = action;

  return {
    ...state,
    loadingClasses: false,
    classes: payload
  }
}

const setDataAttributes = (state = INITIAL_STATE, action: IFilterSetAttributesAction) => {
  const { payload } = action;
  const loadingAttributes = false;

  const attributes = payload instanceof Array
    ? [...payload]
    : [...state.attributes, payload];

  return { ...state, loadingAttributes, attributes }
}

const clientError = (state = INITIAL_STATE, action: IStationErrorAction) => ({
  ...state,
  loading: false,
  loadingAttributes: false,
  error: action.payload
});

export default createReducer(INITIAL_STATE, {
  [Types.START_LOADING_CLASSES as string]: startLoadingClasses,
  [Types.START_LOADING_ATTRIBUTES as string]: startLoadingAttributes,
  [Types.CLIENT_ERROR as string]: clientError,
  [Types.SET_DATA_CLASSES as string]: setDataClasses,
  [Types.SET_DATA_ATTRIBUTES as string]: setDataAttributes,
  [Types.RESET_ATTRIBUTES as string]: resetAttributes,
});

export function defineParam(param: IFilterParams) {
  return (dispatch: Dispatch) => dispatch(Creators.setParam({ param }));
}

export function listClasses(stationCode: string | string[]) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(Creators.startLoadingClasses());
      const url = stationCode instanceof Array
        ? `estacoes/comparar/atributos?codEstacoes=${stationCode.join(',')}&agruparPor=intervalo`
        : `estacoes/${stationCode}/atributos?agruparPor=intervalo`;

      const result = await fetchApi(url, { method: 'GET' });
      dispatch(Creators.setDataClasses(normalizeStationClasses(result.data)));
    } catch (error) {
      handleError(error);
      dispatch(Creators.clientError(error));
    }
  };
}

export function listAttributes(
  stationCode: string | string[],
  phenomenonCode: string,
  startDate: string,
  endDate: string
) {
  return async (dispatch: Dispatch) => {
    try {
      const url = stationCode instanceof Array
        ? `estacoes/comparar/fenomenos?codEstacoes=${stationCode.join(',')}`
          + `&codFenomeno=${phenomenonCode}&dataInicio=${startDate}&dataFinal=${endDate}`
        : `estacoes/${stationCode}/fenomenos/${phenomenonCode}?`
          + `dataInicio=${startDate}&dataFinal=${endDate}`;

      dispatch(Creators.startLoadingAttributes());

      const { data } = await fetchApi(url, { method: 'GET' });
      dispatch(Creators.setDataAttributes([]));

      let normalized: any = null;

      if (stationCode instanceof Array) {
        normalized = data.map((i: any) => {
          let item = normalizeStationAttributes(i)
          item.phenomenonCode = phenomenonCode;
          item.key = `${item.codStation}_${phenomenonCode}`;
          item.series = [...item.series];
          return item;
        });
      } else {
        normalized = normalizeStationAttributes(data);
        normalized.phenomenonCode = phenomenonCode;
        normalized.key = `${normalized.codStation}_${phenomenonCode}`;
      }

      return dispatch(Creators.setDataAttributes(normalized));
    } catch (error) {
      handleError(error);
      dispatch(Creators.clientError(error));
    }
  }
}

export function clearAttributes() {
  return (dispatch: Dispatch) => {
    dispatch(Creators.resetAttributes());
  }
}
