import React, { useEffect } from 'react';
import { Button, InputNumber, Typography, Row, Col} from 'antd';
import { useMapContext } from '../context';
import { IMapContextState } from 'interfaces/mapContext';
import { PushpinOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setMarker, clearCompareFilter, list, defineLatLong } from 'store/ducks/map';
import { stationType } from 'interfaces/types';
import { clearAttributes } from 'store/ducks/filter';

const SelectManualLocation = ({ handleOverlay }: { handleOverlay: () => void }): JSX.Element => {
  const { hasManualLocation, manualLocationRadiumDistance, setState } = useMapContext();
  const { Text } = Typography;
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(setMarker());
    dispatch(clearCompareFilter());
    dispatch(defineLatLong());
    dispatch(list('A' as stationType));
    dispatch(clearAttributes());

    setState((prev: IMapContextState) => ({
      ...prev,
      compare: false,
      termSearch: null,
      tabPanes: [],
      dispatchManualLocation: false,
      hasManualLocation: false
    }));
  }

  const handleChangeRadiumdistance = (manualLocationRadiumDistance: number = 100) => {
    setState((prevState: IMapContextState) => ({ ...prevState, manualLocationRadiumDistance }));
  }

  const handleSetManualLocation = () => {
    setState((prevState: IMapContextState) => ({
      ...prevState,
      hasManualLocation: true
    }));

    handleOverlay();
  }

  useEffect(() => {
    return () => {
      setState((prevState: IMapContextState) => ({
        ...prevState,
        hasManualLocation: false,
        clearModel: prevState.clearModel
      }));
    }
  // eslint-disable-next-line
  }, []);

  const renderRadiumInput = () => {
    if(!hasManualLocation) return null;

    return (
      <Row gutter={8} justify="space-around" className="input-row">
        <Col><Text>Raio em km</Text></Col>
        <Col>
          <InputNumber
            type="number"
            size="small"
            min={50}
            max={300}
            defaultValue={manualLocationRadiumDistance}
            onChange={handleChangeRadiumdistance}
          />
        </Col>
      </Row>
    );
  }

  const renderButtonActions = () => {
    if(!hasManualLocation) {
      return (
        <Button
          size="small"
          shape="round"
          className="button-compare"
          onClick={handleSetManualLocation}
          icon={<PushpinOutlined />}>
          <small>Definir local no mapa</small>
        </Button>
      );
    }

    return (
      <Button
        size="small"
        shape="round"
        className="button-compare active"
        onClick={() => setState((prevState: IMapContextState) => ({
          ...prevState,
          dispatchManualLocation: true
        }))}
        icon={<PushpinOutlined />}>
        <small>Confirmar local</small>
      </Button>
    );
  }

  return (
    <div className={hasManualLocation ? 'manual-location active' : 'manual-location'}>
      {renderRadiumInput()}

      <Row justify="space-around">
        <Col className="col-cancel">
          {hasManualLocation && <Button
            block
            type="link"
            size="small"
            shape="round"
            onClick={handleCancel}
          >
            <small>Cancelar</small>
          </Button>}
        </Col>

        <Col className="col-action">
          {renderButtonActions()}
        </Col>
      </Row>
    </div>
  );
}

export default SelectManualLocation;