import React, { useState, useEffect, useCallback, memo } from 'react';
import { Typography, Button, Row, Col, Divider } from 'antd';
import { IFilter } from 'interfaces/filter';
import { useSelector, useDispatch } from 'react-redux';
import { IStore } from 'interfaces/store';
import { IBaseReduxState } from 'interfaces/baseRedux';
import { IStation, IStationClasses } from 'interfaces/station';
import { useMapContext } from '../../context';
import { listAttributes, clearAttributes } from 'store/ducks/filter';
import { IMapContextState } from 'interfaces/mapContext';
import { unitOfTime } from 'moment';
import { handleError } from 'helpers/error';
import Picker from 'components/Shared/DatePicker';
import moment from 'moment';
import Attributes from './Attributes';

interface IStateModel {
  codStation: string | string[];
  codPhenomenom: string[];
  blocked: boolean;
  startDate?: Date;
  endDate?: Date;
}

const Filter = (): JSX.Element => {
  const { Text } = Typography;
  const {
    classes,
    intervals: mockIntervals,
    loadingClasses,
    loadingAttributes
  } = useSelector<IStore, IFilter>(state => state.filter);

  const {
    items: stations,
    current: selectedStation,
    latLong: latLongSearch
  } = useSelector<IStore, IBaseReduxState<IStation>>(state => state.map);

  const [ currentClassName, setCurrentClassName ] = useState('');
  const [ currentInterval, setCurrentInterval ] = useState('');
  const [ disableFinalDate, setDisableFinalDate ] = useState(true);
  const [ stateClasses, setStateClasses ] = useState<string[]>(Object.keys(classes));
  const [ currentPhenomenon, setCurrentPhenomenon ] = useState<string[]>([]);
  const [ , setIntervals ] = useState([] as string[]);
  const [ phenomenons, setPhenomenons ] = useState([] as string[]);
  const [ phenomenonsLengthView, setPhenomenonsLengthView ] = useState(4);
  const [ model, setModel ] = useState({ blocked: false } as IStateModel);
  const {
    termSearch,
    activeStationsToCompare,
    dispatchManualLocation,
    clearModel,
    setState
  } = useMapContext();

  const spaceDivider = { margin: '10px 0' };
  const dispatch = useDispatch();

  const clearFilter = useCallback(() => {
    setCurrentClassName('');
    setCurrentInterval('');
    setCurrentPhenomenon([]);
    setDisableFinalDate(true);
    setIntervals([]);
    setPhenomenons([]);
    setModel({ codStation: '', codPhenomenom: [], blocked: false, startDate: undefined, endDate: undefined });
    setState((prevState: IMapContextState) => ({ ...prevState, phenomenom: null, tabPanes: [], dispatchManualLocation: false }));
    dispatch(clearAttributes());
  }, [dispatch, setState]);

  /* limpa o model de Filter: intervalo, classses e fenomenos na mudança de lat/long do pin manual */
  useEffect(() => clearFilter, [loadingClasses, dispatchManualLocation, clearFilter]);
  useEffect(() => clearFilter, [clearFilter, selectedStation, clearModel]);

  /** destroe state de comparação ao sair do página */
  useEffect(() => {
    return () => setState((prev: IMapContextState) => ({
      ...prev,
      activeStationsToCompare: 0,
      compare: false,
      termSearch: ''
    }));
  // eslint-disable-next-line
  }, []);

  const activeButtonClasses = () => {
    if (selectedStation) return false;

    const items = stations.filter(station => !station.search);

    if (termSearch) {
      if (activeStationsToCompare === 0 && !items.length) return false;
      return !activeStationsToCompare;
    }

    return items.length > 0;
  }

  const handleSetInterval = (interval: string) => {
    setCurrentInterval(interval);
    setCurrentClassName('');
    setCurrentPhenomenon([]);
    setDisableFinalDate(true);

    if (classes && classes[interval] && classes[interval]['classes']) {
      const items = classes[interval]['classes'] as unknown as string[];
      setStateClasses(items)
    }
  }

  const handleSetClassName = (className: string) => {
    setCurrentClassName(className);
    setCurrentPhenomenon([]);

    const attributes = classes[currentInterval] && classes[currentInterval]['phenomenons'];
    if (!attributes) return handleError('Falha ao obter lista de fenômeno.');

    const data = attributes[className as any] as unknown as IStationClasses[];
    const phenomenons = data.map(item => item.label);
    setPhenomenons(phenomenons);

    const codStation = selectedStation ? selectedStation.codStation : '';
    const stationsCodes = !codStation
      ? stations.map(station => {
        return station.search && station.codStation ? station.codStation : '';
      })
      : codStation;

    setModel(prevState => ({ ...prevState, codStation: stationsCodes }));
    setState((prevState: IMapContextState) => ({
      ...prevState,
      activeStationsOnFilter: {
        index: 1,
        station: selectedStation
      }
    }));
  }

  /* reseta classes e fenomenos selecionados ao mudar o intervalo*/
  useEffect(() => {
    setPhenomenons([]);
    setCurrentPhenomenon([]);
  }, [currentInterval]);

  const getLimitFinalDate = (current: moment.Moment) => {
    let limit = 0;
    let interval: unitOfTime.StartOf = 'days';
    const initalDate = moment(model.startDate);
    if (currentInterval === 'Horário') limit = 91; // 3 meses + 1 dia
    if (currentInterval === 'Diário') limit = 1826; // 5 anos + 1 dia
    if (currentInterval === 'Mensal') { limit = 120; interval = 'month' }
    return initalDate.add(limit, interval);
  }

  const limitInitalDate = (current: moment.Moment) => {
    const minDate = moment('1980-01-01');
    const today = moment();
    return !(minDate.isSameOrBefore(current) && today.isAfter(current));
  }

  const limitFinalDate = (current: moment.Moment) => {
    const today = moment();
    const dateLimit = getLimitFinalDate(current);
    if(dateLimit.isSameOrAfter(today)){
      return !(moment(model.startDate).isSameOrBefore(current) && today.isAfter(current));
    }
    return !(moment(model.startDate).isSameOrBefore(current) && dateLimit.isSameOrAfter(current));
  }

  useEffect(() => {
    if (currentPhenomenon.length) {
      const phenomenons = classes[currentInterval]['phenomenons'];
      const items = phenomenons[currentClassName as any] as unknown as IStationClasses[];

      setModel(prevState => ({
        ...prevState,
        codPhenomenom: items.map(item => {
          return currentPhenomenon.includes(item.label) ? item.codStation : '';
        })
          .filter(Boolean)
      }));
    }
  }, [classes, currentPhenomenon, currentClassName, currentInterval]);

  /* seta a mudança de classes ao selecionar estação */
  useEffect(() => {
    if (classes && (classes['stationClasses'])) {
      setStateClasses((classes['stationClasses'] as unknown as string[]));
    }
  // eslint-disable-next-line
  }, [loadingClasses]);

  /* limpa model a cada nova pesquisa */
  useEffect(() => {
    setCurrentClassName('');
    setCurrentInterval('');
    setDisableFinalDate(true)
    setCurrentPhenomenon([]);
    setIntervals([]);
    setPhenomenons([]);
    setState((prevState: IMapContextState) => ({ ...prevState, tabPanes:[] }));
    setModel({ codStation: '', codPhenomenom: [], blocked: false, startDate: undefined, endDate: undefined });
  }, [
    latLongSearch, setModel,
    setCurrentClassName,
    setCurrentInterval,
    setCurrentPhenomenon,
    setDisableFinalDate,
    setIntervals,
    setPhenomenons,
    setState
  ]);

  const loadAttributeData = () => {
    const { codStation, codPhenomenom: listPhenomena, startDate, endDate } = model;

    if (listPhenomena) {
      const stationsCodes = codStation instanceof Array
        ? stations.filter(station => station.search).map(station => station.codStation || '')
        : codStation;

      setState((prevState: IMapContextState) => ({ ...prevState, phenomenom: currentPhenomenon }));
      setModel(prevState => ({
        ...prevState,
        codStation: stationsCodes,
        blocked: true
      }));

      return Promise.all(listPhenomena.map(phenomenom => {
        return dispatch(listAttributes(stationsCodes, phenomenom, `${startDate}`, `${endDate}`));
      }));
    }
  };

  const intervalHint = () => {
    if (!currentInterval) return '';
    if (currentInterval === 'Horário')
      return ` - intervalo ${currentInterval}: seleção de período limitada a 3 meses a partir data inicial selecionada.`;

    if (currentInterval === 'Diário')
      return ` - intervalo ${currentInterval}: seleção de período limitada a 5 anos a partir data inicial selecionada.`;

    return ` - intervalo ${currentInterval}: seleção de período limitada a 10 anos a partir da data inicial selecionada.`;
  }

  return (
    <div className="main-filter">
      <Text type="secondary" className="subtitle">Selecione um intervalo</Text>
      <Row className="row-params-intervals" align="middle">
        <Attributes
          items={mockIntervals.map(item => item.text)}
          loading={loadingClasses}
          disabled={activeButtonClasses() || model.blocked || !stations.length}
          callback={handleSetInterval}
          value={currentInterval}
        />
      </Row>
      <Divider style={spaceDivider} />
      <Text type="secondary" className="subtitle">Selecione uma classe</Text>
      <Row className="row-params">
        <Attributes
          items={stateClasses.sort()}
          loading={loadingClasses}
          disabled={!currentInterval}
          callback={handleSetClassName}
          value={currentClassName}
        />
      </Row>
      <Divider style={spaceDivider} />
      <Text type="secondary" className="subtitle">Selecione um fenômeno</Text>
      <Row className="row-phenon">
        {!phenomenons.length && <Text>Selecione uma classe para listar os fenômenos disponíveis</Text>}

        <Attributes
          items={phenomenons.sort().slice(0, phenomenonsLengthView)}
          disabled={false}
          callback={setCurrentPhenomenon}
          value={currentPhenomenon}
        />

        {phenomenons.length > phenomenonsLengthView && <Col span={24} className="has-text-right">
          <Button size="small" type="link" onClick={() => setPhenomenonsLengthView(phenomenons.length)}>
            + ver mais fenômenos disponíveis
          </Button>
        </Col>}
      </Row>
      <Divider style={spaceDivider} />
      <Row gutter={10} align="middle">
        <Col xs={24} sm={24}>
          <Text type="secondary" className="subtitle">
            Período <small style={{color: '#696969', textTransform: 'initial'}}>{intervalHint()}</small>
          </Text>
        </Col>
        <Col xs={24} sm={2}><span>Inicial </span></Col>
        <Col xs={24} sm={6}>
          <Picker
            value={model.startDate}
            disabledDate={limitInitalDate}
            disabled={!currentInterval}
            handler={(startDate: string) => {
              setModel((prevState: any) => ({ ...prevState, startDate }));
              setDisableFinalDate(false);
            }}
          />
        </Col>
        <Col xs={24} sm={2} className="col-final-date"><span>Final&nbsp;&nbsp;</span></Col>
        <Col xs={24} sm={6}>
          <Picker
            value={model.endDate}
            disabledDate={limitFinalDate}
            disabled={disableFinalDate}
            handler={(endDate: string) => setModel((prevState: any) => ({
              ...prevState, endDate
            }))}
          />
        </Col>
        <Col xs={24} sm={3}>
          <Button
            block
            type="link"
            size="small"
            shape="round"
            disabled={!currentClassName && !model.startDate && !model.endDate}
            onClick={clearFilter}
          >
            Limpar
          </Button>
        </Col>
        <Col xs={24} sm={5} className="col-button">
          <span>
            <Button
              block
              size="small"
              shape="round"
              loading={loadingAttributes}
              disabled={!model.codStation || !model.codPhenomenom || !model.startDate || !model.endDate}
              onClick={loadAttributeData}
            >
            Visualizar
            </Button>
          </span>
        </Col>
      </Row>
      <Divider />
    </div>
  );
}
export default memo(Filter);
