import querystring from 'query-string';
import { BASE_ENDPOINT, KONG_JWT, IS_DEVELOPMENT} from 'settings';

export const hasSuccessStatus = (response: any) => response.status >= 200 && response.status < 300;

export const getResponseErrorMessage = async (response: any) => {
  const body = await response.json();

  if (body) return body;
  return 'Error';
};

const request = async (endpoint: string, requestOptions: any) => {
  if (requestOptions.query) endpoint += '?' + querystring.stringify(requestOptions.query);

  const options = {
    ...requestOptions,
    body: JSON.stringify(requestOptions.body),
    headers: {
      ...requestOptions.headers,
      'Content-Type': 'application/json'
    }
  }

  if(!IS_DEVELOPMENT) options.headers.apiKey = KONG_JWT

  const response = await fetch(endpoint, options);

  if (hasSuccessStatus(response)) return response.json();

  throw await getResponseErrorMessage(response);
};

export const fetchApi = async (endpoint: string, requestOptions: any) => {
  const options = {
    ...requestOptions,
    headers: { ...requestOptions.headers }
  }

  if(!IS_DEVELOPMENT) options.headers.apiKey = KONG_JWT

  return await request(`${BASE_ENDPOINT}/${endpoint}`, options);
};

export const fetchMaps = async (endpoint: string, requestOptions: any) => {
  return await request(endpoint, {
    ...requestOptions,
    headers: {
      ...requestOptions.headers
    }  });
};
