import React from 'react';
import { Layout, Row, Col, Menu } from 'antd';
import { useHistory } from 'react-router';
import { enMenuUris } from 'interfaces/enums';
import { Link } from 'react-router-dom';
import Container from 'components/Shared/Container';
import logo from 'assets/images/logo_bndmet_horizontal.png';
import badgeBeta from 'assets/images/beta_badge.png';

const Header = () => {
  const { Header: AntHeader } = Layout;
  const history = useHistory();
  const mainStyle = { background: '#fff' }
  const { pathname } = history.location;

  return (
    <div style={{ ...mainStyle, position: 'relative' }} id="start">
      <Container>
        <AntHeader>
          <Row>
            <Col span={12} className="col-logo">
              <h1>
                <Link to={enMenuUris.home}>
                  <img src={logo} alt="" />
                </Link>
              </h1>
            </Col>

            <Col span={12} className="col-menu has-text-right">
              <Menu
                mode="horizontal"
                selectedKeys={[pathname]}
                style={{ lineHeight: '64px' }}
              >
                <Menu.Item
                  key={enMenuUris.about}
                  onClick={() => history.push(enMenuUris.about)}
                >
                  Sobre
                </Menu.Item>

                <Menu.Item
                  key={enMenuUris.map}
                  onClick={() => history.push(enMenuUris.map)}
                >
                  Mapa
                </Menu.Item>

                <Menu.Item
                  key={enMenuUris.methodology}
                  onClick={() => history.push(enMenuUris.methodology)}
                >
                  Metodologia
                </Menu.Item>

                <Menu.Item
                  key={enMenuUris.termOfUse}
                  onClick={() => history.push(enMenuUris.termOfUse)}
                >
                  Termo de Uso
                </Menu.Item>

                <Menu.Item
                  key={enMenuUris.contact}
                  onClick={() => history.push(enMenuUris.contact)}
                >
                  Contato
                </Menu.Item>

                <Menu.Item
                  key={enMenuUris.api}
                  onClick={() => history.push(enMenuUris.api)}
                >
                  Api
                </Menu.Item>
              </Menu>
            </Col>
          </Row>
        </AntHeader>

        <div id="outdated"></div>
      </Container>

      <img
        src={badgeBeta}
        alt="Em fase beta"
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          zoom: .5
        }}
      />
    </div>
  );
}

export default Header;