import React from 'react';
import ParamButtons from './ParamsButton';
import { Skeleton } from 'antd';

interface IProps {
  disabled: boolean;
  loading?: boolean;
  items: string[];
  callback: Function;
  type?: string | string[];
  value: string | string[];
}

const Attributes = (props: IProps) => {
  const { loading, items, callback, disabled, type, value } = props;

  return (
    <>
      {loading
        ? <Skeleton active />
        : items.map((item, key) => (<ParamButtons
          type={type ? type : value}
          value={value}
          disabled={disabled}
          callback={callback}
          text={item}
          key={key}
        />))
      }
    </>
  )
}

export default Attributes;