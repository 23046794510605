import React, { createContext, useState, useContext } from 'react';
import { IMapContextState } from 'interfaces/mapContext';

interface IProps {
  children: React.ReactNode;
}

const MapContext = createContext({} as IMapContextState);

export default function MapProvider({ children }: IProps) {
  const [ state, setState ] = useState({
    compare: false,
    termSearch: '',
    phenomenom: [] as string[],
    isSearch: false,
    activeStationType: 'A',
    activeStationsToCompare: 0,
    defaultRadiumDistance: 100,
    hasManualLocation: false,
    manualLocationRadiumDistance: 100,
    tabPanes: [] as any[],
    clearModel: false
  } as IMapContextState);

  return (
    <MapContext.Provider value={{ ...state, setState }}>
      {children}
    </MapContext.Provider>
  );
}

export function useMapContext() {
  const context = useContext(MapContext);
  if (!context) throw new Error('\'useMapContext\' must be used within a MapContext Provider');

  return context;
}