import React from 'react'
import { Layout, Row, Col, Typography } from 'antd'
import { SITE_NAME } from 'settings'

const Contact = () => {
  const titlePage = `${SITE_NAME} - API - BNDMET`
  document.title = titlePage

  const { Paragraph, Title } = Typography

  return (
    <Layout className="api-page internal-page">
      <Row gutter={50}>
        <Col xs={24} sm={10} className="col-text">
          <div className="wrapper">
            <Title level={1}>{titlePage}</Title>

            <Paragraph>
              A API (Application Programming Interface) é uma interface que trabalha por
              trás do sistema, com um conjunto de rotinas, protocolos, objetos e
              padrões de programação para acesso a um aplicativo de software ou plataforma
              baseado na Web. Uma API proporciona a integração entre sistemas que possuem
              linguagens e tecnologias heterogêneas de maneira ágil e segura,
              como por exemplo, diferentes bases de dados, funcionalidades e ferramentas
              específicas de determinados aplicativos ou serviços especializados.
            </Paragraph>

            <Paragraph>
              O BNDMET disponibiliza uma API como uma solução usada para automatizar
              e integrar as informações entre os diversos serviços de dados. Através dela
              é possível criar, compartilhar, proteger, auditar e gerenciar requisições
              entre o cliente/usuário e os serviços.
            </Paragraph>

            <Paragraph>
              A API captura uma requisição, executa as políticas de segurança definidas para
              o tráfego de dados, busca na bases as informações solicitadas, e por fim
              envia os dados formatados e organizados ao solicitante.
            </Paragraph>

            <Paragraph>
              Considerando o fluxo descrito, a API possui diferentes aplicabilidades,
              tais como:
            </Paragraph>

            <Paragraph>
              <ul>
                <li>
                  Atuar como um filtro de tráfego no momento da integração dos meios: servidores,
                  web, mobile, etc;
                </li>
                <li>Rotear o tráfego dos serviços utilizados;</li>
                <li>Ser a única porta de entrada para os serviços e para a organização dos mesmos;</li>
                <li>Assegurar a proteção dos dados, com um acesso autenticado e limitação de conexão.</li>
              </ul>
            </Paragraph>

            <Paragraph>
              Caso você deseje ter acesso as informações disponibilizadas pela API BNDMET,
              entre em contato conosco. Basta preencher o formulário ao lado. Teremos a grata
              satisfação de lhe conceder o acesso e as orientações para a integração e utilização.
            </Paragraph>
          </div>
        </Col>

        <Col xs={24} sm={14} className="col-text">
          <div className="wrapper">
            <iframe
              frameBorder="0"
              src="https://servicos.decea.gov.br/sac/?a=atd&amp;c=342&amp;
              sub=[API]&amp;source=bndmet
              &amp;%20Solicita%C3%A7%C3%A3o%20de%20Acesso%20%C3%A0%20API%20BND-MET"
              height="860px"
              width="100%"
              title="form"
            />
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default Contact
