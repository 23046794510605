import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Row, Col, Divider, Typography } from 'antd';
import { useMapContext } from '../../context';

const Styles = makeStyles(() => ({
  main: {
    marginBottom: 0,
    '& *': {
      color: '#fff',
      fontSize: 12
    }
  }
}));

interface IProps {
  title?: string;
}

const MockActiveFilter = (props:IProps) => {
  const classes  = Styles();
  const { compare, activeStationType } = useMapContext();
  const activeStatioTypeClassName = !compare && activeStationType === 'M' ? 'conventional' : '';

  const tittle = props.title || 'Selecione uma estação no mapa ou pesquise uma localidade para filtrar as estações mais próxima e seus atributos';

  return (
    <>
      <Row className={`active-filter ${activeStatioTypeClassName} ${classes.main}`}>
        <Col xs={24} className="col-station-compare-0 has-text-center">
          <div className="inner-box">
            <Typography.Text>{tittle}</Typography.Text>
          </div>
        </Col>
      </Row>
      <Divider />
    </>
  );
}

export default MockActiveFilter