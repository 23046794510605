import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import map from './map';
import filter from './filter';

const appReducer = (history: History) => combineReducers({
  map,
  filter,
  router: connectRouter(history)
});

const rootReducer = (history: History) => (state: any, action: any) => {
  return appReducer(history)(state, action);
}

export default rootReducer;