import React, { useCallback, useEffect } from 'react';
import { Menu, Input, Button } from 'antd';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { handleError } from 'helpers/error';
import {
  defineLatLong,
  list as listStations,
  stopLoading as stopLoadingStations
} from 'store/ducks/map';
import { useMapContext } from '../context';
import { IMapContextState } from 'interfaces/mapContext';
import { stationType } from 'interfaces/types';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import { clearAttributes } from 'store/ducks/filter';

const Styles = makeStyles(() => ({
  dropdownMenu: {
    borderRadius: 6,
    marginTop: 2,
    position: 'absolute',
    zIndex: 10,
    width: 450,

    '& .ant-menu-item': {
      fontSize: 12,
      marginBottom: '0 !important',
      marginTop: '0 !important'
    }
  }
}));

const PlacesSuggest: React.FC = (): JSX.Element => {
  const classes = Styles();
  const [ searchPlaceTerm, setSearchPlaceTerm ] = React.useState('');
  const { termSearch, setState } = useMapContext();
  const dispatch = useDispatch();

  const handleChange = (address: string) => {
    setSearchPlaceTerm(address);
    if (address) return;

    dispatch(defineLatLong());

    /* reload data */
    dispatch(listStations('A' as stationType));
    setState((prevState: IMapContextState) => ({
      ...prevState,
      termSearch: undefined,
      compare: false,
      tabPanes: [],
      phenomenom: null
    }));

    dispatch(clearAttributes());
  }

  const handleSelect = useCallback((address: string) => {
    setSearchPlaceTerm(address);
    dispatch(defineLatLong());

    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        setState((prevState: IMapContextState) => ({ ...prevState, termSearch: address }));
        dispatch(defineLatLong(latLng));
      })
      .catch(error => {
        dispatch(stopLoadingStations());

        const message = error === 'ZERO_RESULTS'
          ? 'Não há estações para o termo pesquisado'
          : error;

        handleError(message);
      });
  }, [dispatch, setState]);

  const onError = (status: string, clearSuggestions: Function) => {
    const message = status === 'ZERO_RESULTS'
      ? 'Não há estações para o termo pesquisado'
      : status;

    handleError(message);
    clearSuggestions();
  }

  /* limpa o campo de busca quando a busca é reseta no componente ActiveStationBar */
  useEffect(() => {
    if (termSearch === null) setSearchPlaceTerm('');
  }, [termSearch]);

  return (
    <PlacesAutocomplete
      debounce={500}
      value={searchPlaceTerm}
      onChange={handleChange}
      onSelect={handleSelect}
      shouldFetchSuggestions={searchPlaceTerm.length > 2}
      onError={onError}
      searchOptions={{
        componentRestrictions: {country: 'br'},
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <Input
            allowClear={!loading}
            suffix={!loading
              ? <SearchOutlined className="certain-category-icon" />
              : <Button loading type="link" size="small" className="suggest-loading" />
            }
            {...getInputProps({
              placeholder: 'Pesquisar localidade'
            })}
          />

          <div className="has-text-left" style={{borderRadius: 8, marginTop: 10}}>
            <Menu className={classes.dropdownMenu}>
              {suggestions.map(suggestion => {
                return (
                  <Menu.Item
                    {...getSuggestionItemProps(suggestion)}
                  >
                    {suggestion.description}
                  </Menu.Item>
                );
              })}
            </Menu>
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export default PlacesSuggest;