import React from 'react';
import { Layout, Col, Row } from 'antd';
import { useLocation } from 'react-router-dom';
import Container from 'components/Shared/Container';
import logoVerticalWhite from 'assets/images/logo_bndmet_vertical_white.png';
import logoVerticalWhiteText from 'assets/images/logo_bndmet_text_vertical_white.png';
import logoDecea from 'assets/images/dom_decea.png';
import logoInmet from 'assets/images/inmet.png';
import logoSac from 'assets/images/logo_sac.svg';
import logoIcao from 'assets/images/logo_icao.svg';
import logoDeceaText from 'assets/images/logo_DECEA_text.png';
import logoImmetText from 'assets/images/logo_INMET_text.png';
import logoSacText from 'assets/images/logo_sac_text.svg';
import logoIcaoText from 'assets/images/logo_icao_text.svg';

const Footer: React.FC = () => {
  const location = useLocation();

  const { Footer: AntFooter } = Layout;
  const isHome = location.pathname === '/';

  const bndMet = isHome ? logoVerticalWhiteText : logoVerticalWhite;
  const decea = isHome ? logoDeceaText : logoDecea;
  const inmmet = isHome ? logoImmetText : logoInmet;
  const sac = isHome ? logoSacText : logoSac;
  const icao = isHome ? logoIcaoText : logoIcao;

  return (
    <AntFooter className={isHome ? 'main-footer-home' : 'main-footer-inner'}>
      <Container>
        <Row>
          <Col xs={24} sm={3} className="owner">
            <img src={bndMet} alt="BDNMET - Banco Nacional de Dados de Metereologia" />
          </Col>

          <Col xs={24} sm={21} className="co-owners">
            <img src={inmmet} alt="INMET" className="inmet" />
            <img src={decea} alt="DECEA" className="dcea" />
            <img src={sac} alt="SAC" className="sac" />
            <img src={icao} alt="ICAO" className="icao" />
          </Col>
        </Row>
      </Container>
    </AntFooter>
  );
}

export default Footer;