import React, { useState } from 'react'
import { Layout, Row, Col, Typography, Button } from 'antd'
import Container from 'components/Shared/Container'
import ArrowRightOutlined from '@ant-design/icons/RightOutlined'
import { useHistory } from 'react-router'
import { enMenuUris } from 'interfaces/enums'
import videoThumb from  'assets/images/mock-video.png'

const Home = () => {
  document.title = 'BND-MET'
  const { Paragraph, Title } = Typography
  const { Content } = Layout
  const history = useHistory()
  const [showVideo, setShowVideo] = useState(false);
  const ytbUrl = 'https://www.youtube.com/embed/2YqLuGGnBVM?controls=0&amp;&amp;showinfo=0&amp;rel=0';

  return (
    <div className="main-home">
      <Container className="main-home-container">
        <Row>
          <Col sm={12} xs={24} className="col-video">
            <div className="wrapper">
              <div className="inner-wrapper">
                <div className="video">
                  {!showVideo && <div
                    className="thumb"
                    onClick={() => setShowVideo(true)}
                  >
                    <img src={videoThumb} className="image" alt="BNDMET apresentação" />
                  </div>}

                  <iframe
                    width="560"
                    height="315"
                    title="BDNMET vídeo"
                    src={`${ytbUrl}&amp;autoplay=${showVideo ? 1 : 0}`}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    style={{visibility: showVideo ? 'visible' : 'hidden'}}
                  />
                </div>
              </div>
            </div>
          </Col>

          <Col sm={12} xs={24} className="col-text-actions">

            <Content>
              <Title className="main-title">Integração de dados e facilidade de acesso em prol da sociedade</Title>
              <Paragraph className="text">
                O BNDMET aumenta a capacidade de análise do cenário climatológico
                para implementação de empreendimentos e ações que tenham a influência do
                tempo meteorológico como critério de sua viabilidade.
              </Paragraph>

              <div className="buttons">
                <Button
                  shape="round"
                  onClick={() => history.push(enMenuUris.map)}
                >
                  PESQUISAR NO MAPA
                </Button>
                <span className="more">
                  <a href="#sobre" onClick={() => history.push(enMenuUris.about)}>saiba mais</a>
                  <ArrowRightOutlined />
                </span>
              </div>
            </Content>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Home
