import React, { useEffect, useCallback, memo, useState, useRef } from 'react';
import { LatLngLiteral, PathOptions } from 'leaflet';
import * as ReactLeaflet from 'react-leaflet';
import { Marker } from 'react-leaflet';
import BrazilGeoJson from 'data/brazil.ibge.json';
import { useSelector, useDispatch } from 'react-redux';
import {
  list as listStations,
  setMarker,
  listByDistance
} from 'store/ducks/map';
import { listClasses } from 'store/ducks/filter';
import { IStation } from 'interfaces/station';
import { IStore } from 'interfaces/store';
import { enBaseColors } from 'interfaces/enums';
import { IBaseReduxState } from 'interfaces/baseRedux';
import { handleError } from 'helpers/error';
import { stationType } from 'interfaces/types';
import { useMapContext } from '../context';
import { IMapContextState } from 'interfaces/mapContext';
import Typography from 'antd/lib/typography';
import Button from 'antd/es/button';

const Map = ({ type }: { type: stationType }) => {
  const { Map: LeafletMap, TileLayer, GeoJSON, Circle, CircleMarker, Popup } = ReactLeaflet;

  const {
    compare: compareStations,
    hasManualLocation,
    manualLocationRadiumDistance,
    dispatchManualLocation,
    termSearch,
    setState
  } = useMapContext();

  /** center map position */
  const defaultPosition: LatLngLiteral = {
    lat: -15.28, lng: -56.24
  };

  const defaultManualLocation:LatLngLiteral = {
    lat: -11.263708937105367, lng: -49.61405836327535
  }

  const defaultMapType = type;
  const defaultZoom = 4.45;
  const incremetZoom = 9.2;
  const defaultRadiumDistance = 100;

  const [ position, setPosition ] = useState(defaultPosition);
  const [ zoom, setZoom ] = useState(defaultZoom);
  const [ classZoom, setClassZoom ] = useState(defaultZoom);

  const geoJSONStyle: PathOptions = {
    color: '#de5850',
    weight: 1.5,
    fill: true,
    fillOpacity: 0.2,
    fillColor: '#fff',
  }

  const { items: stations, latLong } = useSelector<IStore, IBaseReduxState<IStation>>(
    (state: IStore) => state.map
  );

  const dispatch = useDispatch();
  const loadData = useCallback(() => {
    dispatch(listStations(defaultMapType));
  }, [ dispatch, defaultMapType ]);

  const loadClasses = useCallback((stationCode: string) => {
    dispatch(listClasses(stationCode));
  }, [ dispatch ]);

  /* Carregar as classes das estações quando está no modo busca */
  useEffect(() => {
    if (compareStations && (latLong || hasManualLocation)) {
      const codStations = stations.map((station, index) => {
        if (index <= 2) return station.codStation || '';
        return '';
      });
      codStations.length && dispatch(listClasses(codStations.filter(Boolean)));
    }
  }, [dispatch, compareStations, latLong, hasManualLocation, stations]);

  /* reseta o zoom ao limpar a busca */
  useEffect(() => {
    if (defaultPosition.lat !== position.lat && !compareStations) {
      setPosition(defaultPosition);
      setZoom(defaultZoom);
    }
  }, [compareStations, defaultPosition, position]);

  const handleCompareStations = useCallback(() => {
    if (stations.filter(station => station.compare === true).length < 3) {
      return true;
    }

    handleError('Selecione até 3 estações para comparar', 'warning');
    return false;
  }, [stations]);

  const handleClick = useCallback((pin: IStation) => {
    /* block click on the marker when search mode is active */
    if (termSearch) return;

    pin.size = 14;
    pin.weight = 1;
    pin.compare = compareStations && handleCompareStations();

    if (pin.compare) {
      const itemsToCompareLength = stations.filter(s => s.compare === true).length;
      const size = itemsToCompareLength === 0
        ? itemsToCompareLength
        : itemsToCompareLength - 1;

      const colors = [ enBaseColors.PRIMARY, enBaseColors.SECONDARY, enBaseColors.THIRD ];

      pin.size = 14;
      pin.color = colors[size];
    }

    dispatch(setMarker(pin));
    pin.codStation && loadClasses(pin.codStation);
  }, [compareStations, stations, termSearch, dispatch, loadClasses, handleCompareStations]);

  const getStationClassnameByType = (type: string) => {
    if (type === 'Automatica') return 'map--popup station--automatic';
    if (type === 'Convencional') return 'map--popup station--manual';
    return 'map--popup station--outher';
  }

  /* Start manual pin */
  const [manualPinPosition, setManualPinPosition] = useState(defaultManualLocation);
  const refManualPin = useRef();
  const refConfirmPinLocation = useRef();

  const nearbyStations = useCallback((newPosition, newZoom, mapType, radiumDistance) => {
    setPosition(newPosition);
    setZoom(newZoom);
    dispatch(listByDistance(newPosition, mapType, radiumDistance));
    setState((prevState: IMapContextState) => ({
      ...prevState, compare: true,
      termSearch: (hasManualLocation) ? `${manualPinPosition.lat},${manualPinPosition.lng}` : termSearch
    }));
  }, [dispatch, setState, hasManualLocation, manualPinPosition.lat, manualPinPosition.lng, termSearch]);

  /* Focar no Marker quando o usuário clicar para selecionar um local manualmente */
  useEffect(() => {
    if (hasManualLocation) {
      setPosition(manualPinPosition);
      setZoom(5);
    }
  // eslint-disable-next-line
  }, [hasManualLocation]);

  const updateManualPinPosition = () => {
    const marker = refManualPin.current;

    // @ts-ignore
    if (marker) setManualPinPosition(marker.leafletElement.getLatLng());
  }

  const handleConfirmManualLocation = useCallback(() => {
    const popup = refConfirmPinLocation.current;
    // @ts-ignore
    if(popup) popup.leafletElement.options.leaflet.map.closePopup();

    nearbyStations(manualPinPosition, incremetZoom, defaultMapType, manualLocationRadiumDistance);
  },[defaultMapType, manualLocationRadiumDistance, manualPinPosition, nearbyStations, refConfirmPinLocation])

  const renderhasManualLocation = () => {
    if(!hasManualLocation) return null
    return (
      <Marker
        draggable
        // @ts-ignore
        ref={refManualPin}
        position={manualPinPosition}
        onDragend={updateManualPinPosition}
      >
        <Popup
          // @ts-ignore
          ref={refConfirmPinLocation}
          minWidth={80}
          closeOnClick
          className="marker-manual"
        >
          <div>
            <Typography.Text type="secondary" className="subtitle">
              Confirmar localização?
            </Typography.Text>
            <Button
              block
              type="primary"
              shape="round"
              size="small"
              onClick={handleConfirmManualLocation}
            >
              Sim
            </Button>
          </div>
        </Popup>
        <Circle
          center={[manualPinPosition.lat, manualPinPosition.lng]}
          radius={manualLocationRadiumDistance * 1000}
        >
          <div>
            <span> Vaca JAIRO </span>
          </div>
        </Circle>
      </Marker>
    );
  }

  /* gatilho para iniciar a busca manual após clique no botão confirmar (abaixo da busca)*/
  useEffect(() => {
    if (dispatchManualLocation) {
      handleConfirmManualLocation();
    }
  }, [dispatchManualLocation, handleConfirmManualLocation]);
  // End Manual Pin

  useEffect(() => loadData(), [loadData]);

  useEffect(() => {
    if (latLong) nearbyStations(latLong, incremetZoom, defaultMapType, defaultRadiumDistance);
  }, [latLong, nearbyStations, incremetZoom, defaultMapType, defaultRadiumDistance]);

  return (
    <LeafletMap
      animate
      center={position}
      zoom={zoom}
      // closePopupOnClick={false}
      // onpopupclose={}
      onzoomend={(event) => setClassZoom(event.target._zoom)}
      className={`map--type-${defaultMapType} map--zoom-${classZoom}`}
    >
      <GeoJSON
        data={BrazilGeoJson as GeoJSON.GeoJsonObject}
        style={geoJSONStyle} />

      {stations.map((station, key) => (
        <CircleMarker
          key={key}
          center={station.position}
          color={station.color}
          fillOpacity={station.opacity || .8}
          radius={classZoom > 6 ? classZoom * 2 : station.size || 6}
          weight={station.weight || 0}
          onClick={() => handleClick(station)}
          className="marker"
        >
          <Popup
            auto
            className={getStationClassnameByType(station.type)}
            closeButton={false}
            onClose={() => (!termSearch) ? dispatch(setMarker()) : null}
          >
            <div className="text-bold">{station.dcName}/{station.sgState}</div>
            <div className="text-bold">{station.sgEntity}</div>
          </Popup>

          {station.weight && <Circle
            center={[station.position.lat, station.position.lng]}
            weight={0}
            radius={80000}
          />}
        </CircleMarker>
      ))}

      {latLong &&
         <Marker position={latLong} color="#e0c40b">
           <Circle
             weight={0}
             center={[latLong.lat, latLong.lng]}
             radius={defaultRadiumDistance * 1000}
           />
         </Marker>
      }

      {renderhasManualLocation()}

      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
      />
    </LeafletMap>
  );
}

export default memo(Map);