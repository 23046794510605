import differenceInHours from 'date-fns/differenceInHours';
import { enStationsType } from 'interfaces/enums';

interface IStoredData {
  time: Date;
  data: string;
}

export function storeData(data: string, key: string) {
  localStorage.setItem(
    `bdmnet_${key}`,
    JSON.stringify({ time: new Date(), data })
  );
}

export function getStoredData(key: string) {
  const storedData = localStorage.getItem(`bdmnet_${key}`);

  if (!storedData || !JSON.parse(storedData)) return null;
  const data: IStoredData = JSON.parse(storedData)

  if (!isValidStoredData(data)) return null;
  return JSON.parse(data.data);
}

export function isValidStoredData(data: IStoredData) {
  const { time } = data;
  if (!time) return false;

  const dateDiff = differenceInHours(new Date(), new Date(time));
  if (dateDiff < 24) return true;

  Object.keys(enStationsType)
    .map(type =>
      localStorage.removeItem(`bdmnet_stations_${type}`)
    );
  return false;
}