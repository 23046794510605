import { createActions, createReducer } from 'reduxsauce';
import { Dispatch } from 'redux';
import { handleError } from 'helpers/error';
import { fetchApi } from 'helpers/api';
import {
  normalizeStations, normalizeStationsDistance
} from 'helpers/stationsNormalize';
import {
  IStation,
  IStationSetDataAction,
  IStationStartLoadingAction,
  IStationErrorAction,
  IStationSetCurrentAction,
  IFilterSetLatLongAction,
  IStationSetTotals
} from 'interfaces/station';
import { LatLngLiteral } from 'leaflet';
import { IBaseReduxState } from 'interfaces/baseRedux';
import { storeData, getStoredData } from 'helpers/localStorage';
import { stationColor } from 'helpers';
import { enStationsType } from 'interfaces/enums';
import { stationType } from 'interfaces/types';

export const { Types, Creators } = createActions({
  setData: ['payload'],
  clientError: ['payload'],
  setCurrent: ['payload'],
  setLatLong: ['payload'],
  setTotals: ['payload'],
  clearStationToCompare: ['payload'],
  startLoading: [],
  clearFilter: []
});

const INITIAL_STATE: IBaseReduxState<IStation> = {
  items: [],
  loading: true,
  current: undefined,
  latLong: undefined,
  error: undefined,
  totalStations: {
    automaticas: 0,
    convencionais: 0,
    total: 0
  }
};

export const defaultPinStationProps = {
  size: 5,
  weight: 0,
  opacity: .8,
  compare: false,
  search: false
}

const startLoading = (state = INITIAL_STATE, action?: IStationStartLoadingAction) => ({
  ...state, items: [], loading: action?.payload || true
});

const setData = (state = INITIAL_STATE, action: IStationSetDataAction) => ({
  ...state, loading: false, current: undefined, error: undefined, items: [ ...action.payload ]
});

const setCurrent = (state = INITIAL_STATE, action: IStationSetCurrentAction) => {
  const { items } = state;
  const payload = action && action.payload ? action.payload : undefined;

  if (!payload) {
    return {
      ...state,
      loading: false,
      current: undefined,
      items: items.map(item => ({ ...item, ...defaultPinStationProps }))
    }
  }

  return {
    ...state,
    loading: false,
    error: undefined,
    current: payload ? { ...payload } : undefined,
    items: [ ...items ]
  }
};

const setTotals = (state = INITIAL_STATE, action: IStationSetTotals) => ({
  ...state, totalStations: {...action.payload}
});

const setLatLong = (state = INITIAL_STATE, action: IFilterSetLatLongAction) => ({
  ...state, loading: !!action.payload, latLong: action.payload || null
});

const clientError = (state = INITIAL_STATE, action: IStationErrorAction) => ({
  ...state,
  loading: false,
  error: action.payload
});

const clearFilter = (state = INITIAL_STATE, action?: IStationStartLoadingAction) => ({
  ...state,
  current: undefined,
  latLong: undefined,
  items: [...state.items.map(s => ({
    ...s,
    ...defaultPinStationProps
  }))]
});

const clearStationToCompare = (state = INITIAL_STATE, action: IStationSetCurrentAction) => {
  const { items } = state;
  const { payload } = action;

  const index = items.indexOf(payload as IStation);
  items[index] = {
    ...payload,
    ...defaultPinStationProps
  } as IStation;

  return { ...state, items: [...items] }
};

export default createReducer(INITIAL_STATE, {
  [Types.SET_DATA as string]: setData,
  [Types.SET_CURRENT as string]: setCurrent,
  [Types.START_LOADING as string]: startLoading,
  [Types.SET_LAT_LONG as string]: setLatLong,
  [Types.SET_TOTALS as string]: setTotals,
  [Types.CLEAR_STATION_TO_COMPARE as string]: clearStationToCompare,
  [Types.CLIENT_ERROR as string]: clientError,
  [Types.CLEAR_FILTER as string]: clearFilter
});

export function totalStations() {
  return async (dispatch: Dispatch) => {
    try {
      const key = 'stations_total';
      const stored = getStoredData(key);
      if (stored) return dispatch(Creators.setTotals(stored));

      const result = await fetchApi('estacoes?tipo=todas', {
        method: 'GET'
      });

      const data = {
        automaticas: result.totalAutomaticas,
        convencionais: result.totalConvencionais,
        total: result.totalEstacoes
      };

      storeData(JSON.stringify(data), key);
      dispatch(Creators.setTotals(data));
    } catch (error) {
      handleError(error);
      dispatch(Creators.clientError(error));
      console.log('aaaa')
    }
  };
}

export function list(type: stationType) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(Creators.startLoading());
      const stationsType = enStationsType[type];

      const stored = getStoredData(`stations_${stationsType}`);
      if (stored) return dispatch(Creators.setData(stored.flat(1)));

      const result = await fetchApi(`estacoes?tipo=${stationsType}`, {
        method: 'GET'
      });

      const normalizedResult = normalizeStations(result.data);
      storeData(JSON.stringify(normalizedResult), `stations_${stationsType}`);

      dispatch(Creators.setData(normalizedResult));
      return normalizedResult;
    } catch (error) {
      handleError(error);
      dispatch(Creators.clientError(error));
    }
  };
}

export function listByDistance(position: LatLngLiteral, type: stationType, distance: number = 100) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(Creators.startLoading());
      const url = `estacoes-proximas?tipo=${enStationsType[type]}`
        + `&latitude=${position.lat}&longitude=${position.lng}`
        + `&distancia=${distance}`;

      const result = await fetchApi(url, { method: 'GET' });
      const markers = result.data.flat(1);

      if (!markers || !markers.length) {
        throw Error('Não foi possível encontrar estações para o local desejado');
      }

      const stationType: stationType = type !== 'M' && type !== 'T' ? 'A' : 'M';
      const normalized = normalizeStationsDistance(markers)
        .map((marker, index) => {
          if (index < 3) {
            return {
              ...marker,
              color: stationColor(index),
              size: 14,
              compare: true,
              search: false,
              type: stationType
            }
          }

          return { ...marker, size: 10 };
        });
      dispatch(Creators.setData(normalized));
    } catch (error) {
      handleError(error);
      dispatch(Creators.clientError(error));
    }
  };
}

export function setMarker(marker?: IStation) {
  return (dispatch: Dispatch) => dispatch(Creators.setCurrent(marker));
}

export function defineLatLong(latLong?: LatLngLiteral) {
  return (dispatch: Dispatch) => dispatch(Creators.setLatLong(latLong));
}

export function clearCompareFilter() {
  return (dispatch: Dispatch) => {
    dispatch(Creators.clearFilter());
  }
}

export function removeStationFromItemsToCompare(station: IStation) {
  return (dispatch: Dispatch) => {
    dispatch(Creators.clearStationToCompare(station));
  }
}

export function stopLoading() {
  return (dispatch: Dispatch) => {
    dispatch(Creators.clientError());
  }
}