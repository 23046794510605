import React, { memo, useState } from 'react';
import { Row, Col, Typography, Divider, Button, Progress } from 'antd';
import { makeStyles, CSSProperties } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { IStore } from 'interfaces/store';
import { IStation } from 'interfaces/station';
import { IBaseReduxState } from 'interfaces/baseRedux';
import {
  setMarker,
  clearCompareFilter,
  list,
  defineLatLong
} from 'store/ducks/map';
import { formatDate } from 'helpers';
import { useMapContext } from '../../context';
import { listClasses, clearAttributes } from 'store/ducks/filter';
import { stationType } from 'interfaces/types';
import { IMapContextState } from 'interfaces/mapContext';
import CloseIcon from '@ant-design/icons/CloseOutlined';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import MockActiveFilter from './MockActiveFilter';
import SearchBar from './SearchBar';

const Styles = makeStyles(() => ({
  main: {
    marginBottom: 0,
    '& *': {
      color: '#fff',
      fontSize: 12
    }
  }
}));

const ActiveStationBar: React.FC = () => {
  const classes  = Styles();
  const { Title, Text } = Typography;
  const { items, current, loading } = useSelector<IStore, IBaseReduxState<IStation>>(state => state.map);
  const { compare, termSearch, activeStationType, setState } = useMapContext();

  const currentStation = current ? [current] : [];
  const stationsToCompare: IStation[] = items.filter(item => item.compare === true);

  let stations = stationsToCompare.length
    ? stationsToCompare
    : currentStation as IStation[];

  const [, setModel] = useState(stations);

  const renderText = (primaryText: string, secondaryText: string | number) => {
    if (stations && stations.length > 1) {
      return (<Title level={4}>{primaryText}: <span>{secondaryText}</span></Title>);
    }

    return (
      <>
        <Title level={4}>{primaryText}</Title>
        <Text>{secondaryText}</Text>
      </>
    );
  }

  const dispatch = useDispatch();
  const getStationsClasses = () => {
    const codStations = stations.map(item => {
      if (item.search && item.codStation) return item.codStation;

      return '';
    })
      .filter(Boolean);

    dispatch(listClasses(codStations));
  }

  const actionStationSelect = (index: number) => {
    const station = stations[index];
    station.search = !station.search;
    setModel(stations);

    const actives = stations.filter(s => s.search)
    setState((prev: IMapContextState) => ({...prev, activeStationsToCompare: actives.length}));

    if(actives.length) {
      getStationsClasses();
      return;
    }

    /* limpa o model quando não há estações ativas para comporação */
    setState((prev: IMapContextState) => ({...prev, clearModel: !prev.clearModel }));
  }

  const isStationSelected = (index: number) => {
    const station = stations[index];
    return station.search;
  }

  const RendeActionIcon = ({ index }: { index: number }): JSX.Element => {
    if (!compare) return <></>;

    const codStation = stations[index].codStation || '';
    return !!stations.find(station => station.codStation === codStation)
      ? (<CheckCircleFilled className={`checkbox-selected index-${index}`} />)
      : (<CheckCircleOutlined className="checkbox" />);
  }

  const mainColSize = 24 / stations.length;
  const isMandatorySize = (colSize: number) => mainColSize !== 24 ? 24 : colSize;

  const noteStyle: CSSProperties = {
    fontStyle: 'italic',
    color: '#fff',
    display: 'block',
    textAlign: 'right',
    marginTop: 12
  };

  const parseDate = (date: Date) => {
    const stringDate = JSON.parse(JSON.stringify(date));
    const parsed = new Date(stringDate);
    return formatDate(parsed);
  }

  const clearStationStoreState = () => {
    dispatch(setMarker());
    dispatch(clearCompareFilter());
    dispatch(list('A' as stationType));
    setState((prev: IMapContextState) => ({
      ...prev,
      compare: false,
      termSearch: null,
      tabPanes: [],
      dispatchManualLocation: false,
      hasManualLocation: false,
      clearModel: !prev.clearModel,
      phenomenom: null,
    }));
    stations = [] as IStation[];
    dispatch(clearAttributes());
    dispatch(defineLatLong());
  }

  if (loading) {
    return (
      <Progress
        strokeColor={{'0%': '#073545', '100%': '#AAD2DF'}}
        showInfo={false}
        status="active"
        percent={100}
        style={{marginBottom: 15}}
      />
    )
  }

  if (!stations.length && !compare) return <MockActiveFilter />;

  if(!stations.length){
    return(
      <>
        <Row gutter={10} className={`active-filter ${classes.main}`}>
          <SearchBar stations={stations} onClear={clearStationStoreState} />
        </Row>
        <MockActiveFilter title="Não foi possível encontrar estações para o local desejado."/>
      </>
    )
  }

  const activeStatioTypeClassName = !compare && activeStationType === 'M' ? ' conventional' : '';

  return (
    <>
      <Row gutter={10} className={`active-filter ${activeStatioTypeClassName} ${classes.main}`}>
        {(termSearch !== '' && termSearch) && <SearchBar stations={stations} onClear={clearStationStoreState} />}
        {stations.map((station, index) => {
          const {
            dcName: name,
            sgState: state,
            sgEntity: entity,
            cdStatus: status,
            alt,
            dtEndOperation,
            dtStartOperation,
            distance,
            search
          } = station;

          const nameColSize = dtEndOperation ? 8 : 11;

          return (
            <Col
              key={index}
              xs={mainColSize}
              className={`col-station-compare col-station-compare-${index}${compare ? ' compare' : ''}`}
            >
              <div className={`inner-wrapper${isStationSelected(index) ? ' active' : ''}`}>
                <div className="inner-box" onClick={() => actionStationSelect(index)}>
                  {
                    mainColSize < 24 && <Button
                      type="primary"
                      shape="circle"
                      className={
                        search
                          ? `button-action selected index-${index}`
                          : 'button-action'
                      }
                      icon={termSearch ? <RendeActionIcon index={index} /> : <CloseIcon />}
                    />
                  }

                  <Row>
                    <Col xs={isMandatorySize(nameColSize)}>{renderText('Estação', `${name}/${state}`)}</Col>
                    <Col xs={isMandatorySize(4)}>{renderText('Responsável', entity || '')}</Col>
                    {mainColSize < 24 && <Col xs={24}>
                      <Row gutter={8}>
                        <Col xs={8}>
                          {renderText('Situação', '')}
                          <div style={{display: 'block', marginTop: 8}}>{status || ' - '}</div>
                        </Col>

                        <Col xs={8} className="has-text-center">
                          {renderText('Altitude', '')}
                          <div style={{display: 'block', marginTop: 8}}>{alt ? `${alt}m` : ''}</div>
                        </Col>

                        <Col xs={8} className="has-text-center">
                          {renderText('Dt. Início', '')}
                          <div style={{display: 'block', marginTop: 8}}>
                            {dtStartOperation ? formatDate(dtStartOperation) : ''}
                          </div>
                        </Col>
                      </Row>
                    </Col>}

                    {mainColSize === 24 && <>
                      <Col xs={isMandatorySize(3)}>{renderText('Situação', status || ' - ')}</Col>
                      <Col xs={isMandatorySize(3)}>{renderText('Altitude', alt ? `${alt}m` : '')}</Col>
                      <Col xs={isMandatorySize(3)}>
                        {renderText('Dt. Início', dtStartOperation ? parseDate(dtStartOperation) : ' - ')}
                      </Col>
                    </>}
                  </Row>

                  {dtEndOperation && <div style={noteStyle}>
                    Estação inativada em: {formatDate(dtEndOperation)}
                  </div>}

                  {termSearch && distance && <div style={noteStyle}>
                    {`à ${distance}Km do ponto pesquisado`}
                  </div>}
                </div>
              </div>
            </Col>
          );
        })}
      </Row>

      <Divider />
    </>
  );
}

export default memo(ActiveStationBar);