import React, { useState, useEffect, memo } from 'react';
import { Layout, Row, Col, Progress, Tabs, Alert } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useSelector } from 'react-redux';
import { IStore } from 'interfaces/store';
import { IFilter, IPhenomenomAttribute } from 'interfaces/filter';
import { highStockOptionsConfig } from 'helpers/highchartsConfig'
import { stationType } from 'interfaces/types';
import { makeStyles } from '@material-ui/styles';
import { useMapContext } from './context';
import { enBaseColors } from 'interfaces/enums';
import { IBaseReduxState } from 'interfaces/baseRedux';
import { IStation } from 'interfaces/station';
import { IChartOptions, IChartOptionsSeries } from 'interfaces/charts';
import { getStoredData, storeData } from 'helpers/localStorage';
import { SITE_NAME } from 'settings';
import Map from './components/Map';
import Filter from './components/Filter';
import PlacesSuggest from './components/PlacesSuggest';
import SelectManualLocation from './components/SelectManualLocation';
import ActiveStationBar from './components/ActiveStationBar';
import HighchartsReact from 'highcharts-react-official';
import HighStock from 'highcharts/highstock';
import HighchartExport from 'highcharts/modules/exporting';
import HighchartExportData from 'highcharts/modules/export-data';
import HighchartExportAccessibility from 'highcharts/modules/accessibility';
import FormMap from './components/FormMap';
import AnimatedMarker from 'assets/images/animated-marker.gif';
import AnimatedDrag from 'assets/images/animated-drag-right.gif';

HighchartExport(HighStock);
HighchartExportAccessibility(HighStock);
HighchartExportData(HighStock);

HighStock.setOptions(highStockOptionsConfig);

const useStyles = makeStyles(() => ({
  root: {
    '& div[style]': {
      overflow: 'visible !important'
    }
  }
}));

interface ITabPane {
  title: string;
  key: string;
  chartOptions: IChartOptions;
}

const Home = () => {
  document.title = `${SITE_NAME} - Mapa de Estações!`;

  const { Content } = Layout;
  const [ mapType, setMapType ] = useState<stationType>('A');

  const { items: stations } = useSelector<IStore, IBaseReduxState<IStation>>(state => state.map);
  const { loadingAttributes, attributes } = useSelector<IStore, IFilter>(state => state.filter);

  const {
    tabPanes,
    activeStationsOnFilter: activeStations,
    phenomenom
  } = useMapContext();
  const [ activePane, setActivePane ] = useState('');
  const [ chartPanes = [], setChartPanes ] = useState([...tabPanes] as ITabPane[]);
  const [ manualPinModalTip, setManualPinModalTip ] = useState(false);

  const classes = useStyles();

  const getIntervalTooltipFormat = (interval: string) => {
    if (interval === 'Horario') return '%d/%m/%y %Hhr';
    if (interval === 'Diario') return '%A, %e %b %Y';
    if (interval === 'Mensal') return '%B, %Y';
    return '';
  };

  const noPhenomenomData = () => {
    return (phenomenom?.length > 0 && Object.keys(attributes).length <= 0) && (!loadingAttributes);
  }

  const hasChartSeriesData = (data: IChartOptionsSeries[]): boolean => {
    if (!data) return false;

    const { data: series } = data[0];
    return !(!series || (series && !series.length) || (!Object.keys(series).length));
  }

  /**
   * Obtém as configurações do gráfico para exibir os dados de uma
   * única estação ou de múltiplas
   */
  const getChartOptions = (attr: any, chartData:any = null) => {
    let { unity = '', frequency: interval, series: data, name } = attr;
    let intervalTooltipFormat = getIntervalTooltipFormat(interval);

    const activeStation = stations.find(station => station.codStation === attributes[0].codStation);
    const stationName = activeStations?.station?.dcName
      ? activeStations?.station?.dcName
      : activeStation?.dcName;

    let series: IChartOptionsSeries[] = [{
      name: stationName || '',
      color: activeStation?.color || enBaseColors.PRIMARY,
      data
    }];

    if (attr instanceof Array) {
      const [first] = attr;
      name = first.name
      unity = first.unity || '';
      intervalTooltipFormat = getIntervalTooltipFormat(first.frequency);
      series = chartData
    }

    return {
      series,
      title: { text: name },
      caption: { text: '' },
      yAxis: { labels: { format: `{value} ${unity}` } },
      tooltip: {
        valueSuffix: ` ${unity}`,
        color: enBaseColors.PRIMARY,
        valueDecimals: 1,
        xDateFormat: intervalTooltipFormat,
        split: true
      },
      chart: {
        reflow: true
      },
    } as IChartOptions;
  }

  /* Exibir o gráficos em abas ao realizar uma consulta ou selecionar estação */
  useEffect(() => {

    if (!attributes.length) return;
    const quantSelectedStations = new Set(attributes.map(attribute => attribute.codStation)).size;

    let chartsToLoad : ITabPane[] = []

    if (quantSelectedStations === 1) {
      for (const attribute of attributes) {
        const existsChart = chartPanes?.find((p) => p.key === attribute.phenomenonCode);
        const chartOptions = getChartOptions(attribute)
        const tab: ITabPane = { title: attribute.name, key: attribute.phenomenonCode, chartOptions };

        if (existsChart) {
          const index = chartPanes.findIndex(q => q.key === attribute.phenomenonCode);
          chartPanes[index] = { ...tab };
          setChartPanes([...chartPanes]);
        } else {
          chartsToLoad.push(tab);
        }
      }
    } else if(quantSelectedStations > 1){
      const phenomenonCode = attributes[0].phenomenonCode
      const existsChart = chartPanes?.find((p) => p.key === phenomenonCode)

      const data = attributes.map((attribute: IPhenomenomAttribute, key: number) => {
        const station = stations.find(station => station.codStation === attribute.codStation);
        return { name: station?.dcName, color: station?.color, data: attribute.series };
      });

      const chartOptions = getChartOptions(attributes, data);
      const title = attributes[0].name;
      const tab: ITabPane = { key: phenomenonCode, title, chartOptions };

      if(existsChart){
        const index = chartPanes?.findIndex(q => q.key === phenomenonCode);
        chartPanes[index] = { ...tab };
        setChartPanes([...chartPanes]);
      } else {
        chartsToLoad.push(tab);
      }
    }

    const newPanes = [...chartsToLoad, ...chartPanes];

    if (newPanes.length) {
      setChartPanes([...newPanes]);
      setActivePane(newPanes[0].key);
    }
    // eslint-disable-next-line
  }, [attributes, tabPanes, stations]);

  /* limpa as abas ao clicar no botão limpar da filtro */
  useEffect(() => {
    if (!phenomenom) setChartPanes([]);
  }, [phenomenom]);

  const handleOverlay = () => {
    const key = 'manual_pin_tip';
    const hasView = getStoredData(key);

    if (hasView) return;

    setManualPinModalTip(true);
    storeData('true', key);
  }

  const renderNoDataChart = () => (
    <div className={`${classes.root} card-container`}>
      <Alert
        message="Não há dados disponíveis para o período selecionado."
        type="warning"
        style={{marginTop: 15}}
      />
    </div>
  );

  return (
    <Layout className="main-content map">
      <Row>
        <Col xs={24} sm={10} className="main-map">
          <div className="inner-content" >
            <div className="form">
              <PlacesSuggest />
              <SelectManualLocation handleOverlay={handleOverlay} />
              <FormMap
                handleChangeMapType={(e: RadioChangeEvent) => setMapType(e.target.value)}
              />
            </div>
          </div>

          {manualPinModalTip && <div
            className="overlay-manual-pin"
            onClick={() => setManualPinModalTip(false)}
          >
            <div className="wrapper">
              <div className="wrapper-gifs">
                <div className="pin">
                  <img src={AnimatedMarker} alt="" />
                </div>
                <div>
                  <img src={AnimatedDrag} alt="" />
                </div>
              </div>
              <div className="text-tip">
                Arraste o marcador pelo mapa para definir a sua área geográfica de pesquisa
              </div>
            </div>
          </div>}

          <Map type={mapType} />
        </Col>

        <Col xs={24} sm={14} className="col-content">
          <Content className="wrapper-content">
            <ActiveStationBar />

            <div className="filter-params">
              <Filter />

              <div style={{marginTop: -15}}>
                {loadingAttributes && <Progress
                  strokeColor={{'0%': '#073545', '100%': '#AAD2DF'}}
                  showInfo={false}
                  status="active"
                  percent={100}
                />}
              </div>
            </div>

            {(!loadingAttributes && Object.keys(attributes).length > 0) && <div className={`${classes.root} card-container`}>
              <Tabs activeKey={activePane} size="small" type="card" onChange={(activeKey) => setActivePane(activeKey)}>
                {chartPanes.map(pane => (<Tabs.TabPane tab={pane.title} key={pane.key}>
                  {
                    !hasChartSeriesData(pane.chartOptions.series)
                      ? renderNoDataChart()
                      : <HighchartsReact
                        highcharts={HighStock}
                        constructorType="stockChart"
                        options={pane.chartOptions}
                      />
                  }
                </Tabs.TabPane>))}
              </Tabs>
            </div>}

            {noPhenomenomData() && renderNoDataChart()}
          </Content>
        </Col>
      </Row>
    </Layout>
  );
}

export default memo(Home);