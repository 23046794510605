import React from 'react';
import { browserHistory } from 'configStore';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { enMenuUris } from 'interfaces/enums';

import Header from './header';
import Footer from './footer';

import HomePage from './Home';
import AboutPage from './About';
import MapPage from './Map';
import TermPage from './TermOfUse';
import MethodologyPage from './Methodology';
import ContactPage from './Contact';
import ApiPage from './Api';

const Main: React.FC = () => {
  return (
    <ConnectedRouter history={browserHistory}>
      <Header />

      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path={enMenuUris.termOfUse} exact component={TermPage} />
        <Route path={enMenuUris.about} exact component={AboutPage} />
        <Route path={enMenuUris.map} exact component={MapPage} />
        <Route path={enMenuUris.methodology} exact component={MethodologyPage} />
        <Route path={enMenuUris.api} exact component={ApiPage} />
        <Route path={enMenuUris.contact} exact component={ContactPage} />
        <Route path="/reload" exact render={() => <div />} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
      <Footer />
    </ConnectedRouter>
  );
}

export default connect(state => state)(Main);
