import React from 'react';
import { DatePicker as Picker } from 'antd';
import ptBr from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import 'moment/locale/pt-br';

interface IProps {
  value: Date | undefined;
  disabled: boolean;
  allowClear?: boolean;
  inputReadOnly?: boolean;
  showToday?: boolean;
  disabledDate: (date: any) => boolean;
  handler: Function;
  size?: 'large' | 'middle' | 'small';
  placeholder?: string;
}

const DatePicker = (props: IProps) => {
  const {
    value,
    disabled,
    disabledDate,
    allowClear = true,
    inputReadOnly,
    handler,
    showToday,
    placeholder = 'dd/mm/yyyy',
    size = 'middle'
  } = props;

  return (
    <Picker
      allowClear={allowClear}
      inputReadOnly={inputReadOnly}
      placeholder={placeholder}
      size={size}
      format="DD/MM/YYYY"
      locale={ptBr}
      disabledDate={disabledDate}
      disabled={disabled}
      showToday={showToday}
      style={{width: '95%'}}
      value={value ? moment(value, 'YYYY-MM-DD') : null}
      onChange={(momentDate) => {
        const date = momentDate ? momentDate.format('YYYY-MM-DD') : '';
        handler(date)
      }}
    />
  )
}

export default DatePicker;