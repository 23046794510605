import { RangeSelectorButtonsOptions } from 'highcharts/highstock';

export const highStockOptionsConfig = {
  rangeSelector: {
    selected: 1,
    inputDateFormat: '%d/%m/%Y',
    inputEditDateFormat: '%d/%m/%Y',
    buttons: [
      { type: 'month', count: 1, text: '1m' },
      { type: 'month', count: 3, text: '3m' },
      { type: 'month', count: 6, text: '6m' },
      { type: 'year', count: 1, text: '1a' },
      { type: 'all', text: 'Todos' }
    ] as RangeSelectorButtonsOptions[],
    buttonTheme: {
      width: 40
    },
  },
  lang: {
    months: [
      'Janeiro', 'Fevereiro', 'Março', 'Abril',
      'Maio', 'Junho', 'Julho', 'Agosto',
      'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ],
    shortMonths: ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'],
    weekdays: [
      'Domingo', 'Segunda', 'Terça', 'Quarta',
      'Quinta', 'Sexta', 'Sábado'
    ],
    downloadCSV: 'Exportar em CSV',
    downloadXLS: 'Exportar em XLS',
    downloadPDF: 'Exportar em PDF',
    downloadPNG: 'Exportar em PNG',
    downloadJPEG: 'Exportar em JPEG',
    downloadSVG: 'Exportar em SVG',
    drillUpText: '◁ Voltar para {series.name}',
    viewFullscreen: 'Ver em tela cheia',
    exitFullscreen: 'Sair tela cheia',
    loading: 'Carregando...',
    printChart: 'Imprimir gráfico',
    rangeSelectorFrom: 'De',
    rangeSelectorTo: 'Até',
    rangeSelectorZoom: 'Zoom',
    resetZoom: 'Limpar Zoom',
    decimalPoint: ',',
    thousandsSep: '.'
  }
}
