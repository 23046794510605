import React, { useEffect, useMemo } from 'react';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import { stationType } from 'interfaces/types';
import { useMapContext } from '../context';
import { IMapContextState } from 'interfaces/mapContext';
import { useDispatch, useSelector } from 'react-redux';
import { totalStations as getTotalStations } from 'store/ducks/map';
import { IBaseReduxState } from 'interfaces/baseRedux';
import { IStore } from 'interfaces/store';
import { IStation } from 'interfaces/station';
import Paragraph from 'antd/lib/typography/Paragraph';

interface IProps {
  handleChangeMapType: (type: RadioChangeEvent) => void;
}

const FormMap = (props: IProps) => {
  const { handleChangeMapType } = props;
  const {
    activeStationType: selectedType = 'A',
    clearModel,
    setState: setMapStateContext
  } = useMapContext();
  const dispatch = useDispatch();

  const { totalStations } = useSelector<IStore, IBaseReduxState<IStation>>(
    state => state.map
  );

  const changeStationMap = (event: RadioChangeEvent) => {
    /** DispatchManualLocation carrega os atributos e calsses das estações */
    setMapStateContext((prev: IMapContextState) => ({
      ...prev,
      activeStationType: event.target.value as stationType,
      dispatchManualLocation: prev.hasManualLocation
    }));

    handleChangeMapType(event);
  }

  const loadTotalStations = useMemo(() => {
    dispatch(getTotalStations());
  }, [dispatch]);

  useEffect(() => loadTotalStations, [loadTotalStations, selectedType]);

  useEffect(() => {
    handleChangeMapType({ target: { value: 'A' }} as RadioChangeEvent);

    setMapStateContext((prev: IMapContextState) => ({
      ...prev,
      activeStationType: 'A' as stationType
    }));
  // eslint-disable-next-line
  }, [clearModel]);

  const typeSelectedStation = selectedType === 'M' ? 'convencionais' : 'automáticas';
  const totalByType = selectedType === 'M'
    ? totalStations?.convencionais
    : totalStations?.automaticas;

  const phrase = `${totalByType} estações ${typeSelectedStation} `
    + `de um total ${totalStations?.total} estações`;

  return (
    <div className="box-options">
      <div className={`options has-text-left${selectedType === 'M' ? ' conventional' : ''}`}>
        <Paragraph><strong>Tipos de Estações</strong></Paragraph>
        <Radio.Group
          value={selectedType as stationType}
          defaultValue={'A' as stationType}
          onChange={changeStationMap}
        >
          <Radio className="radio" value={'A' as stationType}> Automáticas</Radio>
          <Radio className="radio" value={'M' as stationType}> Convencionais</Radio>
        </Radio.Group>

        {totalStations && totalStations.total > 0 && <div className="totals-stations">
          <span>{phrase}</span>
        </div>}
      </div>
    </div>
  );
}

export default FormMap;