import {
  IStationUnformatted,
  IStation,
  IStationDistanceUnformatted,
} from 'interfaces/station';
import { LatLngLiteral } from 'leaflet';
import { enBaseColors } from 'interfaces/enums';
import { IPhenomenomAttribute } from 'interfaces/filter';
import toDate from 'date-fns/toDate';
import moment from 'moment';

export function normalizeStations(stations: IStationUnformatted[]) {
  return stations.map(station => {
    const {
      codOscar, nome, dataFimOperacao, dataInicioOperacao,
      situacao, tipo, latitude, longitude, codWsi, altitudeEmMetros,
      estado, entidadeResponsavel, codEstacao
    } = station;

    const position: LatLngLiteral = {
      lat: Number(latitude),
      lng: Number(longitude),
    };
    const startDate = dataInicioOperacao && moment(dataInicioOperacao);
    const endDate = dataFimOperacao && moment(dataFimOperacao);

    return {
      cdOscar: codOscar,
      dcName: nome,
      dtStartOperation: startDate ? startDate.toDate() : null,
      dtEndOperation: endDate ? endDate.toDate() : null,
      cdStatus: situacao,
      cdDsi: codWsi,
      codStation: codEstacao,
      sgState: estado,
      type: tipo,
      sgEntity: entidadeResponsavel,
      alt: Number(altitudeEmMetros),
      position,
      color: tipo === 'Convencional'
        ? enBaseColors.STATION_MANUAL
        : enBaseColors.STATION_AUTOMATIC
    } as IStation;
  });
}

export function normalizeStationsDistance(stations: IStationDistanceUnformatted[]) {
  return stations.map(station => {
    const {
      estado: sgState,
      codEstacao: codStation,
      entidadeResponsavel: sgEntity,
      longitude,
      latitude,
      altitudeEmMetros: alt,
      regiao: region,
      distanciaEmKm,
      situacao: cdStatus,
      dataInicioOperacao: dtStartOperation,
      dataFimOperacao: dtEndOperation,
      nome: dcName
    } = station;

    return {
      dcName,
      sgState,
      codStation,
      region,
      type: 'D',
      cdStatus,
      sgEntity,
      alt: Number(alt),
      distance: distanciaEmKm ? Number(distanciaEmKm) : 0,
      dtStartOperation: dtStartOperation ? toDate(new Date(dtStartOperation)) : null,
      dtEndOperation: dtEndOperation ? toDate(new Date(dtEndOperation)) : null,
      position: {
        lng: Number(longitude),
        lat: Number(latitude),
      }
    } as IStation;
  });
}

interface IStationAttributesUnformated {
  [key: string]: {
    attributos: string[],
    classesDaEstacao: any;
  }
}

interface IStationPhenomenomsAttributesUnformated {
  codEstacao: string;
  periodicidade: string;
  unidade: string;
  nome:string;
  data: [
    number[]
  ]
}

function getPhenomenomsByClassName(attributes: any) {
  if (!attributes) return null;

  const items: any = {};
  Object.keys(attributes).forEach(attr => {
    const data = attributes[attr];

    data.forEach((item: any) => {
      if (!items[attr]) items[attr] = [];

      items[attr].push({
        codStation: item.codFenomeno,
        label: item.nome,
        frequency: item.periodicidade,
        unity: item.unidade,
        class: item.classe,
        selected: false
      });
    });
  });

  return items;
}

function fixIntervalsOrder(intervals: string[]) {
  const order = [ 'Horário', 'Diário', 'Mensal' ];
  return intervals.sort((a, b) => order.indexOf(a) - order.indexOf(b));
}

export function normalizeStationClasses(params: any) {
  let data: any = {};

  const attributes = params['atributos'];
  const stationClasses = params['classesDaEstacao'];
  const frequency = fixIntervalsOrder(Object.keys(attributes));

  Object.keys(attributes).forEach(attribute => {
    data = {
      ...data,
      frequency,
      stationClasses,
      [attribute]: {
        classes: Object.values(attributes[attribute]['classesDisponiveis']) as string[],
        phenomenons: getPhenomenomsByClassName(
          attributes[attribute]['fenomenosPorClasse']
        )
      }
    }
  });

  return data;
}

export function normalizeStationAttributes(attributes: IStationPhenomenomsAttributesUnformated) {
  const {
    codEstacao: codStation,
    periodicidade: frequency,
    unidade: unity,
    nome: name,
    data: series
  } = attributes;

  return {
    codStation,
    frequency,
    unity,
    name,
    series
  } as unknown as IPhenomenomAttribute;
}
