export enum enMenuUris {
  home = '/',
  about = '/sobre',
  map = '/mapa',
  termOfUse = '/termo-de-uso',
  methodology = '/metodologia',
  contact = '/contato',
  api = '/api',
}

export enum enRowJustify {
  start = 'start',
  end = 'end',
  center = 'center',
  spanceAround = 'space-around',
  spanceBetween = 'space-between'
}

export enum enStationsType {
  A = 'automatica',
  M = 'convencional',
  T = 'todas',
  D = 'distancia'
}

export enum enBaseColors {
  PRIMARY = '#157E62',
  SECONDARY = '#673DC7',
  THIRD = '#894033',
  FOURTH = '#F8AB26',
  STATION_AUTOMATIC = '#05202A',
  STATION_MANUAL = '#FF9F16',
  STATION_OUTHER = '#FF00B1'
}
