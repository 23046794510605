import React from 'react';
import { Layout, Row, Col } from 'antd';
import { enRowJustify } from 'interfaces/enums';

interface IProps {
  span?: number;
  justify?: enRowJustify;
  className?: string;
  children: React.ReactNode;
}

const Container = ({ span, justify, className, children }: IProps) => (
  <Layout className={className || ''}>
    <Row justify={justify || enRowJustify.center}>
      <Col span={span || 20}>{children}</Col>
    </Row>
  </Layout>
);

export default Container;