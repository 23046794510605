import React from 'react';
import { Row, Col, Typography, Button } from 'antd';
import { IStation } from 'interfaces/station';
import { useMapContext } from '../../context';

interface IProps {
  stations: IStation[];
  onClear: () => void;
}

const SearchBar = (props: IProps): JSX.Element => {
  const { stations, onClear } = props;
  const {
    termSearch,
    defaultRadiumDistance,
    hasManualLocation,
    manualLocationRadiumDistance
  } = useMapContext();

  const stationType = stations.find(station => station.type)?.type || '';
  let stationTypeText = stationType;
  let stationLengthText = stations.length > 1 ? 'estações' : 'estação';

  if (stationType === 'A')
    stationTypeText = stations.length > 1 ? 'automáticas' : 'automática';

  if (stationType === 'M')
    stationTypeText = stations.length > 1 ? 'convencionais' : 'convencional';

  const title = hasManualLocation
    ? `Exibindo ${stations.length} ${stationLengthText} ${stationTypeText} mais próximas ao ponto '${termSearch}' `
      + `em um raio de ${manualLocationRadiumDistance}Km`
    : `Exibindo ${stations.length} ${stationLengthText} ${stationTypeText} mais próximas à '${termSearch}' `
      + `em um raio de ${defaultRadiumDistance}Km`;

  const titleWithoutResult = 'Nenhuma estação disponível';

  return (
    <Col xs={24} className="header-title">
      <Row>
        <Col xs={21}>
          <Typography.Title level={4}>{stations.length ? title : titleWithoutResult}</Typography.Title>
        </Col>
        <Col xs={3} className="has-text-right">
          <Button
            block
            type="link"
            size="middle"
            shape="round"
            className="button-link"
            onClick={onClear}
            style={{marginTop: -10}}
          >
            Limpar busca
          </Button>
        </Col>
      </Row>

      {stations.length && <Row className="header-tip-selection">
        <Col xs={24} sm={24}>
          <Typography.Text type="secondary" className="subtitle">
            Selecione uma ou mais Estações para ver seus atributos
          </Typography.Text>
        </Col>
      </Row>}
    </Col>
  );
}

export default SearchBar;