import { notification } from 'antd';
import { IError } from 'interfaces/error';

export function handleError(data: string | IError, type: string = 'error') {
  const message = typeof data === 'object' && data.message
    ? translateMessage(data.message)
    : translateMessage(data.toString());

  const types = [
    { type: 'success', component: notification.success },
    { type: 'info', component: notification.info },
    { type: 'warning', component: notification.warning },
    { type: 'error', component: notification.error },
  ];

  const useMethod = types.find(t => t.type === type);
  const className = `notification--type-${type}`;

  if (!useMethod) {
    notification['info']({ message, className, duration: 2});
    return null;
  }

  useMethod.component({ message, className, duration: 2});
}

function translateMessage(message: string) {
  const messages = [
    { from: 'Failed to fetch', to: 'Falha ao obter dados requisitados' }
  ];

  const tranlated = messages.find(m => m.from === message);
  if (tranlated) return tranlated.to;
  return message;
}