import React from 'react';
import { Layout, Row, Col, Typography } from 'antd';
import { enMenuUris } from 'interfaces/enums';
import { SITE_NAME } from 'settings';
import { HashLink as Link } from 'react-router-hash-link';
import Methology from 'assets/images/methology_1.png';

const Methodology = () => {
  document.title = `${SITE_NAME} - Metodologia`;

  const { Paragraph, Title } = Typography;
  const { Content } = Layout;

  return (
    <Layout className="methodology-page internal-page">
      <Row gutter={50}>
        <Col xs={24} sm={10} className="col-image-margin"></Col>

        <Col xs={24} sm={14} className="col-text">
          <div className="wrapper">
            <Content>
              <Title level={1}>Metodologia</Title>
              <Paragraph>
                Para formar o banco de dados do BNDMET, todas as informações iniciais foram captadas
                do DECEA e do INMET. Os dados foram processados a partir da criação de um rigoroso
                protocolo e através de uma API, mecanismo que integrará os sistemas com o
                cruzamento de dados e informações, facilitando o acesso aos mesmos
                em um único mecanismo de busca.
              </Paragraph>

              <div className="how-to">
                <picture>
                  <img src={Methology} className="image" alt="Metodologia"/>
                </picture>
              </div>

              <Paragraph>
                O grande diferencial dessa plataforma é que ela possui a capacidade de
                armazenar os dados de forma estruturada por meio de uma infraestrutura
                georreferenciada. Captar informações em tempo real, ou fazer prognósticos
                futuros será possível justamente por causa do banco de dados que
                possui um sistema de armazenagem mais amplo e fácil de ser acessado,
                o que permite ser um dos maiores propósitos dessa plataforma.
              </Paragraph>

              <Paragraph>
                <Link to={`${enMenuUris.api}#start`}>Clique aqui para obter mais detalhes sobre a nossa API</Link>
              </Paragraph>
            </Content>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default Methodology;