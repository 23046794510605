import React from 'react'
import { Layout, Row, Col, Typography } from 'antd'
import { SITE_NAME } from 'settings'

const About = () => {
  const { Title, Paragraph } = Typography
  const { Content } = Layout
  const title = 'O que é o BNDMET?'
  document.title = `${SITE_NAME} - ${title}`

  return (
    <Layout className="about-page internal-page">
      <Row gutter={50}>
        <Col xs={24} sm={10} className="col-image-margin" />

        <Col xs={14} className="col-text">
          <div className="wrapper">
            <Content>
              <Title level={1}>{title}</Title>

              <Paragraph>
                É um Banco Nacional de Dados Meteorológicos (BNDMET) criado com o propósito
                de convergir em uma única plataforma todas as informações disponíveis sobre a
                meteorologia brasileira. Através do BNDMET será possível acessar informações
                atualizadas e precisas que poderão ser consultadas a qualquer momento, e de
                qualquer local, sobre as condições meteorológicas atuais ou a partir de qualquer
                data sugerida na pesquisa de dados disponível.
              </Paragraph>
              <Paragraph>
                Nossa missão é concentrar com segurança essas informações, ampliando a base
                de pesquisa climática e oferecendo credibilidade e segurança nas informações
                divulgadas para todos os profissionais que terão acesso à plataforma de dados.
                Uma das prerrogativas do Banco de Dados é aumentar a qualidade dos resultados
                das previsões climatológicas, a partir disso, criar possibilidades para ampliação de
                novas rotas de aviação além de auxiliar a implementação de empreendimentos nas
                mais variadas áreas que passam pela aviação, agricultura e construção civil.
              </Paragraph>

              <Paragraph>
                O BNDMET irá viabilizar o processamento de dados que é formado por um comitê
                gestor do qual fazem parte inicialmente o Departamento de Controle do Espaço
                Aéreo (DECEA) e o Instituto Nacional de Meteorologia (INMET). Além de servirem
                como base de dados, os dois órgãos também irão monitorar todas as informações
                que serão subsidiadas no BNDMET oferecendo ainda mais segurança e
                precisão aos que buscarem informações na plataforma. São eles os principais
                participantes do comitê gestor, que futuramente ainda poderá ser ampliado,
                agregando mais participantes na captação e convergência de informações climáticas.
                Tal concepção se tornou possível pelo apoio irrestrito da Secretaria de
                Aviação Civil (SAC) que, através de um acordo internacional com a
                Organização de Aviação Civil Internacional (ICAO), disponibilizou
                os meios necessários para a realização deste projeto.
              </Paragraph>
            </Content>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default About
