import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './configStore';
import Pages from 'components/Pages';
import MapProvider from 'components/Pages/Map/context';

const store = configureStore();

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <MapProvider>
        <Pages />
      </MapProvider>
    </Provider>
  );
}

export default App;
